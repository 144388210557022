// Here you can add other styles

.c-dark-theme {
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: rgba(255, 255, 255, 0.87);
  }
}